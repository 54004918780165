@import "./variables";
//**********************HEADER*****************//
@font-face {
    font-family: League Gothic;
    src: url("league-gothic.leaguegothic-regular.otf") format("opentype");
}

  .link-nav {
    padding: 0.6rem 0 0.7rem 0;
  }
  
  .pointer {
    width: auto;
    height: 3rem;
    position: relative;
    background: $base-color;
    margin-right: 2px;
    &:after {
      content: "";
      position: absolute;
      left: 0;
      bottom: 0;
      width: 0;
      height: 0;
      border-left: 1.5rem solid $lighter-green;
      border-top: 1.5rem solid transparent;
      border-bottom: 1.5rem solid transparent;
    }
    &:before {
      content: "";
      position: absolute;
      right: -1.5rem;
      bottom: 0;
      width: 0;
      height: 0;
      border-left: 1.5rem solid $base-color;
      border-top: 1.5rem solid transparent;
      border-bottom: 1.5rem solid transparent;
    }
  }
  
  .active {
    background: $secondary-color;
    &:after {
      background: $secondary-color;
    }
    &:before {
      border-left: 1.5rem solid $secondary-color;
    }
  }
  
  .xxx:after {
    border-left: 1.5rem solid $base-color;
  }
  
  .item-beard {
    padding: 0 0.5rem 0 2rem;
    line-height: 3rem;
    color: $white;
    font-size: small;
  }
  
  .btn-cal {
    background-color: transparent;
    border: 1px solid $base-color;
    border-radius: 50%;
    width: 30px;
    color: $base-color;
  }
  
  .react-datepicker__header {
    background-color: transparent !important;
    color: $secondary-color;
    font-weight: bold;
    font-size: 17px;
  }
  
  .react-datepicker__day-name {
    color: $secondary-color !important;
    font-weight: normal;
    font-size: 10px;
  }
  
  .date_day_green_dark {
    color: $base-color !important;
    font-weight: bolder;
  }
  
  .date_day_yellow_dark {
    color: yellow !important;
    font-weight: bolder;
  }
  
  .date_day_red_dark {
    color: gray !important;
    font-weight: bolder;
  }
  .voucher-container {
    overflow-y: scroll;
    height: 400px;
  }
  .voucher-box {
    padding: 19px;
    border: 1px solid silver;
    >p {
      font-size: 12px;
      color: $secondary-color;
      margin-bottom: 0px !important;
    }
    >span {
      font-size: 10px;
      color: red;
    }
  }
  .date_day_orange_dark {
    color: $orange !important;
    font-weight: bolder;
  }
  .react-datepicker__day--selected {
    background: $secondary-color !important;
    border-radius: 50% !important;
    color: $white !important;
  }
  .no-pad {
    padding: 0px !important;
  }
  .activity-text {
    >span {
      color: $secondary-color;
      font-size: 18px;
    }
    >p {
      color: $base-color;
      font-size:  12px;
    }
  }
  .popoverSize {
    width: 30rem !important;
  }
  .popoverSize1{
    width: 390px !important;
  }
  .margin-px {
    margin-bottom: 10px;
    // margin-left: 0px !important;
    padding-top: 10px;
    padding-left: 10px;
  }
  .product-image-size {
    width: auto;
    height: 50px !important;
  }
  .no-underline {
    text-decoration: none !important;
  }
  .bg-hover:hover {
    background-color: #FFF6EF;
    transition: 0.4s;
  }
  .bg-hover2:hover {
    background-color: #FFEBF7;
    transition: 0.4s;
  }
  .bg-hover-taronga:hover {
    background-color: #effbf0;
  }
  .silver-color-icon {
    color: silver;
    left: 91%;
  }
  .icon-on-input {
    position: absolute;
    margin-top: 2%;
    margin-left: 2%;
    z-index: 3;
  }
  
  .arrow::after {
    border-right-color: $base-color !important;
  }
  
  .react-datepicker {
    display: grid !important;
    border: 0px !important;
  }
  
  .icon-on-left {
    padding: 0 0.5rem 0 0;
    line-height: normal;
  }
  .btn-bg-green {
    background-color: $secondary-color;
  }
  .btn-voucher {
    text-align: center;
    .btn {
      // border-radius: 0px !important;
      margin-bottom: 10px;
    }
    .custom-button{
      width: 100%;
    }
  }
  .div-flex {
    display: flex;
    padding-left: 0;
  }
  
  .edit-style {
    position: absolute;
    text-align: right;
    font-size: 13px;
    font-weight: bold;
    padding-top: 18px;
    color: $secondary-color !important;
    right: 1px;
    cursor: pointer;
  }
  .text-empty-time {
    margin-left: 4px;
    font-size: 14px;
    color: red;
  }
  .text-second-need-activate {
    margin-left: 4px;
    font-size: 14px;
    color: $darker-gray;
  }
  .order-sum-style {
    /* width: auto;
    height: 100%;
    border: 0.1rem solid $secondary-color;
    padding: 1rem;
    margin: 0.5rem 1rem 1rem 4rem; */
    position: fixed;
    right: 0;
    width: 28rem !important;
    height: auto;
    border: 0.1rem solid $secondary-color;
    padding: 1rem;
    margin: 0.5rem 15rem 1rem 2rem;
  }
  
  .order-sum-style-mobile {
    /* width: auto;
    height: 100%;
    border: 0.1rem solid $secondary-color;
    padding: 1rem;
    margin: 0.5rem 1rem 1rem 4rem; */
    position: fixed;
    right: 0;
    width: 28rem !important;
    height: auto;
    border: 0.1rem solid $secondary-color;
    margin: 0.5rem 15rem 1rem 2rem;
  }
  .field-text-step5 {
    color: $secondary-color;
    margin-bottom: 2%;
  }
  
  .loading {
    &::before {
      content: " ";
      height: 100%;
      width: 100%;
      position: fixed;
      top: 0;
      left: 0;
      z-index: 999;
      background: rgb(255, 255, 255);
      border-radius: 3px;
    }
    &::after {
      content: " ";
      position: fixed;
      top: 40%;
      left: 50%;
      width: 46px;
      height: 46px;
      margin: 1px;
      border-radius: 50%;
      border: 5px solid $secondary-color;
      border-color: $secondary-color transparent;
      animation: loading 1.2s linear infinite;
      z-index: 1001;
    }
  }
  
  @keyframes loading {
    0% {
      transform: rotate(0deg);
    }
  
    100% {
      transform: rotate(360deg);
    }
  }
  
  
  .when-focus {
    &::placeholder, + .icon-on-input {
      color: $white;
    }
    background-color: $base-color;
    color: $white;
  }

  .warning-customer{
    font-size: 14px;
    color: red;
  }

  .warning-customer-promo{
    font-size: 13px;
    color: red;
  }
  .success-customer-promo{
    font-size: 13px;
    color: $lighter-green;
  }
  .ro:read-only {
    background-color: white !important;
  }
  .done-focus {
    &::placeholder, + .icon-on-input {
      color: $white;
    }
    background-color: $secondary-color;
    color: $white;
  }
  
  .when-focus:focus {
    background-color: $base-color;
    color: $white;
  }
  
  #numberParticipant {
    padding-left: 7%;
  }
  
  .form-control:focus {
    box-shadow: unset;
  }
  
  .btn-popover {
    font-size: 0.7rem;
    border-radius: 2rem;
    width: 100%;
  }
  .btn-confirmation {
    border-color: $base-color !important;
    background-color: $base-color !important;
  }
  .btn-secondary {
    border-color: $base-color;
    background-color: $base-color;
  }
  
  .style-total {
    position: absolute;
    padding-right: 7%;
    right: 0;
    color: $base-color;
    font-size: 1.4rem;
    font-weight: bold;
  }
  
  body {
    font-family: "Roboto", sans-serif;
    letter-spacing: 1px;
  }
  
  .head-card {
    padding: 15px 10px 0px 15px;
  }
  
  .btn-min {
    border: 1px solid $base-color !important;
  }
  
  .span-min {
    margin-right: -35px;
    text-align: center;
    margin-top: 3px;
  }
  
  .span-plus {
    margin-left: -35px;
    text-align: center;
    margin-top: 3px;
  }
  
  .font-color-step5 {
    color: $secondary-color;
  }
  
  .card-step5 {
    border: 1px solid $base-color;
    margin-top: 20px;
    padding: 10px;
    font-weight: bold;
    border-radius: 3px;
  }
  
  .btn-redem {
    position: absolute;
    top: 35px;
    background-color: transparent;
    border: 0;
    color: gray;
    font-size: 25px;
    &:hover, &:not(:disabled):not(.disabled):active {
      background-color: transparent;
      color: black;
    }
  }
  .main-activity {
    .popover {
      margin-top: 60px !important;
    }
    .bs-popover-right {
      .arrow {
        margin-top: 0px !important;
      }
    }
  }
  .popover {
    margin-top: 120px !important;
  }
  .bs-popover-right {
    .arrow {
      margin-top: 2px !important;
    }
  }
  .not-available {
    .popover {
      margin-top: 47px !important;
    }
    .bs-popover-right {
      .arrow {
        margin-top: 7px !important;
      }
    }
  }
  ul {
    list-style-type: none;
  }
  
  .card-profile .invalid-feedback{
    position: absolute;
    top: 46%;
    font-size: 10px;
    left: 11%;
  }
  .form-control.is-valid{
    background-image: unset;
    box-shadow: unset;
  }
  
.cardbody-step3 {
    padding-top: 0px;
}

.stotal{
  color: $gray;
  margin-bottom: 2%;
}
.textnumber{
  text-align: right;
  font-weight: bold;
  color: $secondary-color;
}
.hidden{
  visibility: hidden;
}
.s-width{
  max-width: 22rem;
}
.s-color{
  color: $secondary-color;
}
.b-color{
  color: $base-color;
}
.g-color{
  color: $lighter-green;
}
.s-linew{
  line-height: 10px;
}
.s-head{
  margin: auto;
  align-items: center;
  justify-content: center;
}
.box-voucher {
  >h3 {
    text-align: right;
    color: white;
    font-weight: bold;
  }
  .text-desc {
    color: white;
    .title {
      font-size: 22px !important;
      font-weight: bold;
    }
    >p {
      margin: 0;
    }
    >p:nth-child(2) {
      font-size: 12px;
      font-weight: 700;
    }
    >p:nth-child(3) {
      font-size: 10px;
    }
  }
  border: 1px solid silver;
  border-radius: 5px;
  padding: 14px;
  background: $lighter-green;
  margin-bottom: 10px;
}
.timeavail-pad{
  padding: 1px;
  height: 45px;
}
.padl{
  padding-left: 9% !important; 
}
.padlw{
  width: 93% !important;
  padding-left: 1.0rem !important;
}
.lsstyle{
  list-style-type: circle;
  margin-top: 0;
  color: $lighter-green;
}
.bt-danger{
  color: red;
  margin-top: -5px;
}
.letterspace{
  letter-spacing: 0px;
}
.marginbottom{
  margin-bottom: -1.5%;
}
.fonts{
  font-size: 14px;
}
.mpstyle{
  margin: auto;
  padding-left: 15px;
}

@media only screen and (max-width: 1366px){
//   .react-datepicker__header {
//     width: 80% !important;
//   }
//   .react-datepicker .react-datepicker__day-name{
//     margin-left: unset !important;
//     margin-right: unset !important;
//   }
//   .react-datepicker .react-datepicker__day{
//     margin-left: unset !important;
//     margin-right: unset !important;
//   }
//   .react-datepicker .react-datepicker__week{
//     padding-left: unset !important;
//     padding-right: unset !important;
// }
// .react-datepicker__month{
//     text-align: unset !important;
// }
}
  
@media only screen and (max-width: 480px) {
    .edit-style {
      right: 10px;
    }
    .order-sum-style .order-sum-style-mobile {
      position: unset;
      margin: unset;
      display: none !important;
    }
    .row.mbl-reverse {
      flex-direction: column-reverse;
    }
    .card .row {
      .col-md-1.col-xs-1 {
        width: 0;
      }
      .col-md-10.col-xs-10 {
        width: unset;
      }
    }
    .head-card {
      padding: 15px 10px 0px 25px;
    }
    .popover {
      display: none !important;
    }
    .stepper-wrapper{
      display: unset !important;
    }
    .pointer:after {
      border-left: 1rem solid $lighter-green;
      border-top: 1rem solid transparent;
      border-bottom: 1rem solid transparent;
    }
    .xxx:after {
      border-left: 1rem solid $base-color;
    }
    .active:before{
      border-left: unset;
    }
    .item-beard{
      line-height: 2rem;
    }
    .pointer{
      height: 2rem;
    }
    .pointer:before{
      right: unset;
      border: unset;
    }
    .pointer.xxx{
      height: unset;
      margin: 5px 0 0 0;
    }
    .card .row .head-card {
      padding: 11px 10px 0px 25px !important;
  }
  .style-total {
    padding-right: unset;
  }
  .child-detail{
    width: unset !important;
  }
  .div-input-number {
    margin-top: unset;
  }
  .time-available {
    width: unset !important;
    margin: unset !important;
  }
  .timeavail-pad {
    padding: unset !important;
    height: unset !important;
    width: unset !important;
  }
  .mpstyle{
    padding-left: 60px;
  }
}
@media only screen and (max-width: 360px) {
  .react-datepicker .react-datepicker__day-names, .react-datepicker .react-datepicker__week {
    padding-left: unset !important;
    padding-right: unset !important;
}
.react-datepicker__day-name{
    width: 1.7rem !important;
}
  .react-datepicker .react-datepicker__day{
    width: 1.7rem !important;
}
.centerstyle{
  font-size: 12px !important;
}
.style-total{
  right: unset !important;
}
}
// Forbidden page
.text-forbidden {
  color: $base-color;
}
.back-button {
  color: white;
  border: 1px solid $base-color;
  padding: 6px 15px 6px 11px;
  background: $base-color;
  border-radius: 8%;
  &:hover {
    color:white;
}
}

.css-9gakcf-option{
  background-color: transparent !important;
  color: hsl(0, 0%, 0%) !important;
}
.css-9gakcf-option:hover{
  background-color: #DEEBFF !important;
}

.css-1pahdxg-control{
  box-shadow: none !important;
  border-color: silver !important;
}
.btn.disabled, .btn:disabled{
  opacity: 0.35 !important;
}

.btn-krg {
  height: 34px !important;
  width: 34px !important;
}

.ck_exst_cust {
  margin-top: 20px;
  margin-bottom: 5px;
  margin-left: 5px;
}
.warning-text {
  font-size: 12px;
  padding-bottom: 11px;
  color: red;
}
.select2-container--default  {
  .select2-selection--single {
    height: calc(2em + .75rem + 2px) !important;
    border: 1px solid #ced4da !important;
    .select2-selection__rendered {
      line-height: 45px !important;
    }
    .select2-selection__arrow {
      height: 44px !important;
    }
  }
}
.select2-container--default .select2-selection--single .select2-selection__placeholder {
  color: #ced4da !important;
}

.booking-details {
  .detail-container {
    padding-bottom: 0.5rem;
    padding-top: 0.5rem;
    right: 0;
    height: auto;
    border: .1rem solid $secondary-color;
    padding: 1rem;
    margin: .5rem 15rem 1rem 15.5rem;
  }

  .title {
    color: $base-color;
    font-size: 12px;
    font-weight: bold;
    margin-bottom: 2%;
  }

  .title-text {
    margin: 0;
    padding: 0px 1px 0px 7px;
    color: $base-color;
    font-size: 12px;
    font-weight: bold;
    background-color: $white;
  }

  .label-detail {
    font-size: 12px;
    color: $secondary-color;
  }

  .label-number {
    font-size: 17px;
    color: $secondary-color;
    font-weight: bold;
    text-align: right;
  }

  hr{
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
  }

  .product-detail {
    color: #505d68;
    padding: 4%;
    padding-top: 5%;
    margin-top: 20px;
    padding: 10px;
    font-weight: bold;
    border-radius: 3px;
  }

  .flex-right {
    align-items: flex-end;
  }

  
  .form-check {
    padding-left: 0px;
    input[type="checkbox"] {
        opacity: 0;
    }
    span {
        position: relative;
        display: inline-block;
        padding-left: 22px;
    }
    span::before,
    span::after {
        position: absolute;
        content: "";
        display: inline-block;
    }
    
    span::before{
        height: 16px;
        width: 16px;
        border: 1px solid #ced4da;
        left: 0px;
        top: 3px;
    }
    span::after {
        height: 5px;
        width: 9px;
        border-left: 2px solid;
        border-bottom: 2px solid;
        transform: rotate(-45deg);
        left: 4px;
        top: 7px;
    }

    /*Hide the checkmark by default*/
    input[type="checkbox"] + span::after {
        content: none;
    }
    input[type="checkbox"]:checked + span::after {
        content: "";
    }
    input[type="checkbox"]:checked + span::before {
        border: 1px solid $secondary-color;
    }
  }

  .stat-img {
    height: 50px;
    width: 50px;
  }

  .btn {
    border-radius: 2rem;
    font-size: .8rem;
  }

  .btn-block{
    font-size: 18px;
  }

  .btn-secondary {
    border-color: $base-color;
    background-color: $base-color;
  }

  .btn-secondary.disabled, .btn-secondary:disabled {
    color: $white;
    background-color: #d8d8d8;
    border-color: transparent;
    color: $darkest-gray;
  }
  
}

.confirm-paylink-box {
  border: 1px solid $base-color;
  margin-top: 15px;
  padding: 15px;
  font-weight: bold;
  border-radius: 3px;
  
  .confirm-details{
    color: $secondary-color;
    font-size: 15px;
    div{
        margin-bottom: 2px;
    }
    i{
        color: $green;
    }
    svg{
        margin-top: -4px;
        font-size: 14px;
    }
    span{
        font-weight: normal;
        font-size: 14px;
        color: $secondary-color;
    }
}
}

footer {
  padding: 10px;
  margin-top: 20px;
  vertical-align: middle;
  max-height: 1200px;
  max-width: 100%;
}

.modal-contact-title {
  color: $secondary-color
}

.modal-contact-body {
  color: $secondary-color
}

.modal-contact-data {
  color: $base-color
}

.btn-confirm-contact {
  background-color: transparent !important;
  border-color: transparent !important;
  color: $lighter-green !important;
  padding: 0px !important;
  font-size: 16px !important;
  letter-spacing: unset;
}

.btn-confirm-contact:hover {
  color: $base-color !important;
}

.btn-confirm-contact:focus, .btn-confirm-contact.focus {
  box-shadow: unset !important;
}

#segment_tooltip {
    border-color: $base-color !important;
    border-width: thin !important;
    width: 80% !important;
}

#voucher_tooltip {
  border-color: $base-color !important;
  border-width: thin !important;
  text-align: center;
}

#segment_input_tooltip {  
    border-color: $base-color !important;
    border-width: thin !important;color: var(--color4) !important;
    width: 80% !important;
    text-align: center;
}

@media only screen and (max-width: 795px) {
    #segment_tooltip {
        border-width: thin !important;
        width: 80% !important;
    }

    .tool-tip-background {
        left : 12.5% !important;
    }
    #segment_tooltip::before {
        top: -5%;
        left: 50%;
        transform: rotate(-90deg);
    }

    .place-left::before {
        border-left: 8px solid $base-color !important;
    }
    .place-left::after {
        border-left-color: transparent !important;
        border-left-style: unset !important;
    }
}

@media only screen and (min-width: 796px) and (max-width: 999px) {
    #segment_tooltip {
        border-width: thin !important;
        width: 80% !important;
    }

    .tool-tip-background {
        left : 12.5% !important;
    }
    #segment_tooltip::before {
        top: -10%;
        left: 50%;
        transform: rotate(-180deg);
    }

    .place-left::before {
        border-left: 8px solid $base-color !important;
    }
    .place-top::after {
        border-top-color: transparent !important;
        border-top-style: unset !important;
    }
}

.tool-tip-background {
    opacity: 1 !important;
}

.modal-warning {
  display: block;
  justify-content: center !important;
  text-align: center !important;
  color: $base-color;
}

/* Control the height of the iframe */

// .responsive-iframe {

//   height: 800px;

//   width:120%;

// }
@media (min-width: 280px) {
  .responsive-iframe {
    height: 700px;
    width:115%;
    min-width: 121%;
  }
  .apple-pay-btn {
    margin-left: 55px;
  }
}

@media (min-width: 596px) {
  .responsive-iframe {
    height: 700px;
    width:91%;
    min-width: 63%;
  }
}

@media (min-width: 768px) {

  .responsive-iframe {
    height: 700px;
    width:100%;
  }
}

/* Medium devices (desktops, 992px and up) */

@media (min-width: 992px) {

  .responsive-iframe{

    height: 700px;

    width:110%;

  }

}

/* Large devices (large desktops, 1200px and up) */

@media (min-width: 1200px) {

  .responsive-iframe {

    height: 700px;
    width:120%;

  }

}

.ticket-collapse, .segment-collapse{
  max-height: 0;
  transition: max-height 0.15s ease-out;
  overflow: hidden;
  
}
.fade-in { 
  animation: fadeIn 0.5s; 
}

.ticket-show{
  max-height: 100vh;
  transition: max-height 0.25s ease-in;
}

.segment-show{
  max-height: 100vh;
  transition: max-height 0.25s ease-in;
  border: 2px solid $secondary-color;
}

@keyframes fadeIn {
  0% { opacity: 0; }
  100% { opacity: 1; }
}

.activity-plus-icon {
  background-color: transparent;
  left: 100%;
  border-color: $base-color;
  color: $base-color;
  border-radius: 100%;
  border:0.5px solid $base-color;
  display: flex;
  justify-content: center;
  align-items: center;
}

.activity-plus-icon:hover {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: $base-color;
  color: white;
  border-radius: 100%;
  left: 100%;
  border:0.5px solid $base-color;
  transition: background-color 250ms linear;
}

.package-container {
  background-color: $secondary-color;
  color: white;
  margin-left: -16px;
  margin-right: -16px;
  h5 {
    padding-top: 4px;
    padding-bottom: 4px;
  }
}

.discount-package {
  .popover {
    margin-top: 60px !important;
    top: -22px !important;
    width: 20% !important;
  }
  .arrow {
    margin-top: 12px !important;
  }
}

.package_tooltip {
  width: 260px;
  border: 1px solid $base-color !important;
}

.sticky-footer {
  bottom: 0;
  right: 34% !important;
  // background-color: $base-color !important;
  background-color: white !important;
  left: 0% !important;
  margin: 0 0 0 0 !important;
  display: block !important;
  width: 100% !important;
}

.footer-phantom {
  display: block;
  padding: 20px;
  height: 60px;
  width: 100%;
}

.order-sum-arrow{    
  border: solid $gray 1px;
  width: 35px;
  border-radius: 20px;
  text-align: center;
  color:white;
  background-color: $gray;
}

.flex-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.flex-container > div {
  background-color: #f1f1f1;
  width: 100px;
  margin: 10px;
  text-align: center;
  line-height: 75px;
  font-size: 30px;
  cursor: pointer;
}

.flex-container > div.selected {
  background-color: $base-color;
  color: white;
}